<template>
  <div class="container">
    <div class="top">
      <div class="top_left">
        <div class="title"> {{topInfo.title}} </div>
        <div class="text"> {{topInfo.text}} </div>
      </div>
    </div>
    <div class="mid">
      <div class="mid_title"> {{midInfo.title}} </div>
      <div class="mid_box">
        <div class="mid_box_item" v-for="item in midInfo.list" :key="item">
          <img class="item_icon" :src="item.icon">
          <div class="item_title"> {{item.title}} </div>
          <div class="item_text"> {{item.text}} </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "product_jkgl",
    data(){
      return{
        topInfo:{
          title: '健康管理',
          text: '海南玉螺专注于诊前、诊中、诊后的全流程管理服务，打造居家、社区、医院三位一体的慢病健康管理体系。' +
            '已实现八大服务功能，健康筛查、健康计划、体征监测、用药计划、医患签约、患者随访、音视频问答、知识库。',
        },
        midInfo:{
          title: '核心优势',
          list:[
            {
              icon: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_zndz.png',
              title: '发力复诊',
              text: '立足精准医患关系，聚焦复诊和重点人群，维系医患关系，做到复诊挖掘。',
            },
            {
              icon: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_icon_bgcx.png',
              title: '方案灵活',
              text: '为不同特征的客户群体，灵活打造个性化慢病管理平台解决方案。',
            },
            {
              icon: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwjhl_dtsj.png',
              title: '多端一体',
              text: '针对医院、社区、医生、患者的多端应用，提供精确顺畅的信息流转平台。',
            },
            {
              icon: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_jkgl_fyqy_.png',
              title: '放眼区域',
              text: '服务范围可覆盖区域，辐射基层社区医院，突破信息孤岛。',
            },
          ]
        }
      }
    },
    mounted(){
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
</script>

<style lang="stylus" scoped>
  .container{
    display flex
    flex-flow column
    background #F5F5F7
    font-family Alibaba PuHuiTi 2.0
    font-weight normal
    .top{
      width 100%
      height 957px
      background url("https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_jkgl_index_.png") no-repeat
      background-size 100% 100%
      box-sizing border-box
      padding 0 140px
      display flex
      .top_left{
        display flex
        flex-flow column
        width 536px
        margin-top 200px
        .title{
          font-size 56px
          line-height 67px
          color #343A40
        }
        .text{
          margin-top 30px
          font-size 20px
          line-height 34px
          color #6C757D
        }
      }
    }
    .mid{
      display flex
      flex-flow column
      box-sizing border-box
      padding 150px 140px 50px 140px
      .mid_title{
        font-size 56px
        line-height 67px
        color #343A40
        text-align center
        margin-bottom 100px
      }
      .mid_box{
        display flex
        flex-wrap wrap
        justify-content space-between
        .mid_box_item{
          display flex
          flex-flow column
          align-items center
          width 360px
          margin-bottom 150px
          .item_icon{
            width 140px
            height 140px
          }
          .item_title{
            font-size 40px
            line-height 56px
            color #343A40
            margin-top 40px
          }
          .item_text{
            font-size 20px
            line-height 28px
            color #6C757D
            margin-top 20px
          }
        }

      }
    }
  }
</style>
